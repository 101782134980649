<template>
  <base-modal-form
    :id="id"
    title="Crear"
    ref="modalform"
    @postSubmit="handlePostSubmit"
    @show="handleShow"
  >
    <template v-slot:formfields>
      <input type="hidden" name="id" v-model="formFields.id" />
      <input type="hidden" name="salary" v-model="formFields.salary" />
      <input
        type="hidden"
        name="maximum_loan"
        v-model="formFields.maximum_loan"
      />
      <div class="flex space-x-4">
        <b-form-group
          label="Número de cédula"
          class="flex-1 required"
          :invalidFeedback="validateField($v.formFields.identity_number)"
        >
          <b-form-input
            name="identity_number"
            type="number"
            :readonly="readOnlyOnEditing()"
            v-model.trim="$v.formFields.identity_number.$model"
            :state="validateState('identity_number')"
          >
          </b-form-input>
        </b-form-group>
        <b-form-group
          label="Nombres"
          class="flex-1 required"
          :invalidFeedback="validateField($v.formFields.first_name)"
        >
          <b-form-input
            name="first_name"
            v-model.trim="$v.formFields.first_name.$model"
            :state="validateState('first_name')"
          >
          </b-form-input>
        </b-form-group>
        <b-form-group
          label="Apellidos"
          class="flex-1 required"
          :invalidFeedback="validateField($v.formFields.last_name)"
        >
          <b-form-input
            name="last_name"
            v-model.trim="$v.formFields.last_name.$model"
            :state="validateState('last_name')"
          >
          </b-form-input>
        </b-form-group>
      </div>
      <div class="flex space-x-4">
        <b-form-group
          label="Dirección"
          class="flex-1 required"
          :invalidFeedback="validateField($v.formFields.address)"
        >
          <b-form-input
            name="address"
            v-model.trim="$v.formFields.address.$model"
            :state="validateState('address')"
          >
          </b-form-input>
        </b-form-group>
        <b-form-group
          label="Ciudad"
          class="flex-1 required"
          :state="validateState('city_id')"
        >
          <input
            type="hidden"
            name="city_id"
            v-model="$v.formFields.city_id.$model"
          />
          <v-select
            :options="cities"
            :reduce="city => city.value"
            label="text"
            v-model.trim="$v.formFields.city_id.$model"
          />
          <b-form-invalid-feedback id="input-1-live-feedback">
            Campo requerido
          </b-form-invalid-feedback>
        </b-form-group>
      </div>
      <div class="flex space-x-4">
        <div class="flex flex-1 space-x-2 items-center">
          <b-form-group label="Empresa" class="flex-1">
            <input
              type="hidden"
              name="enterprise_id"
              v-model="formFields.enterprise_id"
            />
            <v-select
              :options="enterprises"
              :reduce="enterprise => enterprise.value"
              label="text"
              v-model.trim="formFields.enterprise_id"
            />
          </b-form-group>
          <div class="mt-2 transform translate-y-1">
            <b-button
              size="sm"
              variant="outline-primary"
              v-b-modal.modal-enterprise-form
            >
              <i class="glyphicon glyphicon-plus transform -translate-y-1"></i>
            </b-button>
          </div>
        </div>
        <b-form-group
          label="Ocupación"
          class="flex-1 required"
          :invalidFeedback="validateField($v.formFields.occupation)"
        >
          <b-form-input
            name="occupation"
            v-model.trim="$v.formFields.occupation.$model"
            :state="validateState('occupation')"
          >
          </b-form-input>
        </b-form-group>
      </div>
      <div class="flex space-x-4">
        <b-form-group
          label="email"
          class="flex-1 required"
          :invalidFeedback="validateField($v.formFields.email)"
        >
          <b-form-input
            name="email"
            v-model.trim="$v.formFields.email.$model"
            :state="validateState('email')"
          >
          </b-form-input>
        </b-form-group>
        <b-form-group
          label="Teléfono"
          class="flex-1 required"
          :invalidFeedback="validateField($v.formFields.phone)"
        >
          <b-form-input
            name="phone"
            v-model.trim="$v.formFields.phone.$model"
            :state="validateState('phone')"
          >
          </b-form-input>
        </b-form-group>
      </div>
      <div class="flex space-x-4">
        <div class="flex-1">
          <b-form-group
            label="Salario"
            class="required"
            :invalidFeedback="validateField($v.formFields.salary)"
          >
            <b-form-input
              v-currency="optionsCurrency"
              v-model.trim="$v.formFields.salary.$model"
              @keyup.native="handleMaximunLoan"
              :state="validateState('salary')"
              :readonly="formFields.minimumSalary"
            >
            </b-form-input>
          </b-form-group>
          <b-form-checkbox
            name="has_minimum_salary"
            v-model="formFields.has_minimum_salary"
          >
            Maneja salario mínimo.
          </b-form-checkbox>
        </div>
        <b-form-group
          label="Monto máx. de préstamo"
          class="flex-1 required"
          :invalidFeedback="validateField($v.formFields.maximum_loan)"
        >
          <b-form-input
            v-currency="optionsCurrency"
            v-model.trim="$v.formFields.maximum_loan.$model"
            :state="validateState('maximum_loan')"
          >
          </b-form-input>
          <span class="font-semibold text-sm">
            Porcentaje sugerido de cálculo sobre el salario:
            {{ loanPercentaje }}%
          </span>
        </b-form-group>
      </div>
      <enterprise-form
        id="modal-enterprise-form"
        :handlePostSubmit="handlePostSubmitEnterprise"
      />
    </template>
  </base-modal-form>
</template>

<script>
import BaseModalForm from "@/components/Base/BaseModalForm";
import EnterpriseForm from "@/admin/Enterprises/EnterpriseForm";
import { required, minLength, email, numeric } from "vuelidate/lib/validators";
import { customComponentActions } from "@/mixins/actions.js";
import { deserialize } from "jsonapi-fractal";
import { CurrencyDirective } from "vue-currency-input";
import {
  minimumSalaryToListId,
  loanPercentaje
} from "../../config/parameters.js";

export default {
  props: ["handlePostSubmit", "record", "id"],
  mixins: [customComponentActions],
  inject: [
    "clientsRepository",
    "enterprisesRepository",
    "citiesRepository",
    "conceptsRepository"
  ],

  watch: {
    "formFields.has_minimum_salary"(val) {
      const me = this;
      me.formFields.minimumSalary = val;
      me.formFields.salary = val ? me.minimumSalary : 0;
      me.formFields.maximum_loan =
        me.formFields.salary > 0
          ? (me.formFields.salary * me.loanPercentaje) / 100
          : 0;
    }
  },

  components: {
    BaseModalForm,
    EnterpriseForm
  },

  directives: {
    currency: CurrencyDirective
  },

  data() {
    return {
      formFields: {
        id: null,
        identity_number: "",
        first_name: "",
        last_name: "",
        occupation: "",
        address: "",
        enterprise_id: null,
        city_id: null,
        email: "",
        phone: "",
        salary: 0,
        maximum_loan: 0,
        status: "not_accepted",
        minimumSalary: false,
        has_minimum_salary: false
      },

      repository: "clientsRepository",
      primary: "id",
      isString: ["phone"],

      enterprises: [],
      cities: [],
      concepts: [],
      minimumSalary: "",
      loanPercentaje: 0,

      optionsCurrency: {
        locale: "es",
        currency: {
          prefix: "$"
        }
      }
    };
  },

  validations: {
    formFields: {
      identity_number: {
        required,
        minLength: minLength(3)
      },
      first_name: {
        required,
        minLength: minLength(3)
      },
      last_name: {
        required,
        minLength: minLength(3)
      },
      occupation: {
        required,
        minLength: minLength(3)
      },
      address: {
        required,
        minLength: minLength(3)
      },
      city_id: {
        required
      },
      email: {
        required,
        email,
        minLength: minLength(3)
      },
      phone: {
        required,
        minLength: minLength(3)
      },
      salary: {
        required,
        numeric
      },
      maximum_loan: {
        required,
        numeric
      }
    }
  },

  methods: {
    handleShow() {
      const me = this;

      if (me.record) {
        me.formFields = { ...me.record };
      }
    },

    handlePostSubmitEnterprise() {
      this.handleLoadEnterprises();
    },

    async handleLoadEnterprises() {
      const me = this;
      let enterprises = await me.enterprisesRepository.get();

      me.enterprises = [
        {
          value: null,
          text: "Seleccione empresa"
        },
        ...deserialize(enterprises.data).map(enterprise => {
          return {
            value: enterprise.id,
            text: enterprise.name
          };
        })
      ];
    },

    handleMaximunLoan(event) {
      const me = this;

      me.formFields.maximum_loan =
        event.target.value !== ""
          ? (Number(event.target.value) * me.loanPercentaje) / 100
          : 0;
    }
  },

  async mounted() {
    const me = this;
    let conceptsResult = await me.conceptsRepository.get();
    let concepts = deserialize(conceptsResult.data);

    let cities = await me.citiesRepository.get();

    me.cities = [
      {
        value: null,
        text: "Seleccione ciudad"
      },
      ...deserialize(cities.data).map(city => {
        return {
          value: city.id,
          text: city.name
        };
      })
    ];

    me.minimumSalary = concepts.find(
      item => item.id === minimumSalaryToListId
    ).value;

    me.loanPercentaje = concepts.find(item => item.id === loanPercentaje).value;

    me.handleLoadEnterprises();
  }
};
</script>

<style lang="scss" scoped></style>
