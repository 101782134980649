const maximunToListId = "0c7f727b-3ca1-4226-a826-0fc3b844056c";
const profileIdToList = "5fd4afd4-4a66-4832-b149-60409e1db24f";
const minimumSalaryToListId = "5a04581b-2a53-4634-a13d-cb4ad0914cc8";
const loanPercentaje = "3180eee1-1eef-41a5-bf46-e66600061726";

export {
  maximunToListId,
  profileIdToList,
  minimumSalaryToListId,
  loanPercentaje
};
