<template>
  <base-modal-form
    :id="id"
    title="Crear"
    ref="modalform"
    @postSubmit="handlePostSubmit"
    @show="handleShow"
  >
    <template v-slot:formfields>
      <input type="hidden" name="id" v-model="formFields.id" />
      <div class="flex space-x-4">
        <b-form-group label="NIT" class="flex-2 required">
          <b-form-input name="nit" v-model.trim="formFields.nit"></b-form-input>
        </b-form-group>
        <b-form-group
          label="Nombre"
          class="flex-1 required"
          :invalidFeedback="validateField($v.formFields.name)"
        >
          <b-form-input
            name="name"
            v-model.trim="$v.formFields.name.$model"
            :state="validateState('name')"
          >
          </b-form-input>
        </b-form-group>
      </div>
      <div class="flex space-x-4">
        <b-form-group label="Dirección" class="flex-1 required">
          <b-form-input name="address" v-model.trim="formFields.address">
          </b-form-input>
        </b-form-group>
        <b-form-group
          label="Ciudad"
          class="flex-1 required"
          :state="validateState('city_id')"
        >
          <input
            type="hidden"
            name="city_id"
            v-model="$v.formFields.city_id.$model"
          />
          <v-select
            :options="cities"
            :reduce="city => city.value"
            label="text"
            v-model.trim="$v.formFields.city_id.$model"
          />
          <b-form-invalid-feedback>
            Campo requerido
          </b-form-invalid-feedback>
        </b-form-group>
      </div>
      <div class="flex space-x-4">
        <b-form-group label="Representante Legal" class="flex-1 required">
          <b-form-input
            name="legal_representative"
            v-model.trim="formFields.legal_representative"
          >
          </b-form-input>
        </b-form-group>
        <b-form-group label="email" class="flex-1 required">
          <b-form-input name="email" v-model.trim="formFields.email">
          </b-form-input>
        </b-form-group>
        <b-form-group label="Teléfono" class="flex-1 required">
          <b-form-input name="phone" v-model.trim="formFields.phone">
          </b-form-input>
        </b-form-group>
      </div>
    </template>
  </base-modal-form>
</template>

<script>
import BaseModalForm from "@/components/Base/BaseModalForm";
import { required, minLength } from "vuelidate/lib/validators";
import { customComponentActions } from "@/mixins/actions.js";
import { deserialize } from "jsonapi-fractal";

export default {
  props: ["handlePostSubmit", "record", "id"],
  mixins: [customComponentActions],
  inject: ["enterprisesRepository", "citiesRepository"],

  components: {
    BaseModalForm
  },

  data() {
    return {
      formFields: {
        id: null,
        nit: null,
        name: "",
        address: "",
        city_id: null,
        email: "",
        phone: "",
        legal_representative: ""
      },

      repository: "enterprisesRepository",
      primary: "id",
      isString: ["phone"],

      enterprises: [],
      cities: [],
      concepts: []
    };
  },

  validations: {
    formFields: {
      name: {
        required,
        minLength: minLength(3)
      },
      city_id: {
        required
      }
    }
  },

  methods: {
    handleShow() {
      const me = this;

      if (me.record) {
        me.formFields = { ...me.record };
      }
    }
  },

  async mounted() {
    const me = this;

    let cities = await me.citiesRepository.get();

    me.cities = [
      {
        value: null,
        text: "Seleccione ciudad"
      },
      ...deserialize(cities.data).map(city => {
        return {
          value: city.id,
          text: city.name
        };
      })
    ];
  }
};
</script>

<style lang="scss" scoped></style>
